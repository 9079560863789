<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title>
                    <div class="d-flex justify-space-between flex-wrap">
                        <v-btn class="ma-2" dark color="danger" @click="nuevoOperador">
                            <v-icon>mdi-plus</v-icon>
                           Nuevo Operador
                        </v-btn>
                     <!--   <div>
                            <v-btn class="ma-2" color="primary">
                                <v-icon>mdi-cog</v-icon>
                            </v-btn>
                            <v-btn outlined class="ma-2">Import</v-btn>
                            <v-btn outlined class="ma-2">Export</v-btn>
                        </div>-->
                    </div>
                </v-card-title>
                <v-card-title>
                    Operadores
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Búsqueda"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                 locale="es"
                    :search="search"
                    :headers="headers"
                    :items="lista_operadores"
                    item-key="idoperador"
                    
                    class="elevation-1 table-one"
                    multi-sort
                >
                <template v-slot:item.ine="{ item }">
    <a style="cursor: pointer" @click="ver_imagen(item.ine)">{{
                      item.ine
                    }}</a>
  </template>
      <template v-slot:item.licencia="{ item }">
    <a style="cursor: pointer" @click="ver_imagen(item.licencia)">{{
                      item.licencia
                    }}</a>
  </template>
     <template v-slot:item.examen_medico="{ item }">
    <a style="cursor: pointer" @click="ver_imagen(item.examen_medico)">{{
                      item.examen_medico
                    }}</a>
  </template>
 
 

                    <template v-slot:item.action="{item}">

                        <div class="d-flex">
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                    @click="editOperador(item.idoperador)"
                                        color="success"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                    >
                                        <v-icon>mdi-pencil-box-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        color="danger"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        @click="eliminarOperador(item)"
                                    >
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Eliminar</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:item.badge="{item}">
                      <!--  <template v-if="item.badge === 'Active'">
                            <v-chip
                                class=""
                                color="success"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-check</v-icon>
                                {{ item.badge }}
                            </v-chip>
                        </template>
                        <template v-else>
                            <v-chip
                                class=""
                                color="danger"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-close</v-icon>
                                {{ item.badge }}
                            </v-chip>
                        </template>-->
                    </template>
                </v-data-table>
            </base-card>
        </v-col>
    </v-row>
</template>

<script>
import { url } from "../../main.js";

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Operadores'
    },
    data() {
    return {
          search: '',
           // selected: [],
            headers: [
                {
                    text: 'Nombre',
                    align: 'start',
                    sortable: false,
                    value: 'nombre'
                },
               
                {text: 'Apellidos', value: 'apellidos'},
                {text: 'INE', value: 'ine'},
                {text: 'Licencia', value: 'licencia'},
                {text: 'Examen Médico', value: 'examen_medico'},

                {text: 'Acción', value: 'action'}
            ],
     
      edit: false,
      permiso: 0,
     
      operador: {
        idoperador: 0,

        nombre: "",
        apellidos: "",
        fecha_licencia: '',
        fecha_vigencia: '',
        documentacion: {
          ine: null,
          licencia: null,
          examen_medico: null,
        },
      },
      busqueda_nombre: "",
      busqueda_apellidos: "",
      lista_operadores: [],
    };
  },
 
  mounted() {
    this.veroperadores();
  },
  

  methods: {
    veroperadores() {
      this.axios
        .get(url + "apiOperadores.php/operadores")
        .then((respuesta) => {
          console.log(respuesta);
          this.lista_operadores = respuesta.data;

          console.log(this.lista_operadores);
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },
    saveoperador() {
      if (this.validarcampos()) {
        if (this.operador != null) {
          this.operador.fecha_licencia = moment(
            this.operador.fecha_licencia
          ).format("YYYY-MM-DD");
          this.operador.fecha_vigencia = moment(
            this.operador.fecha_vigencia
          ).format("YYYY-MM-DD");

          console.log(this.operador);
          this.axios
            .post(url + "apiOperadores.php/operador", this.operador)
            .then((respuesta) => {
              console.log(respuesta);

              if (respuesta.data != 0) {
                this.edit=false
                this.veroperadores();
                if (this.operador.documentacion.ine != null) {
                  console.log("xxx");
                  console.log(
                    this.uploadFile(
                      this.operador.documentacion.ine,
                      respuesta.data
                    )
                  );
                }

                if (this.operador.documentacion.licencia != null) {
                  this.uploadFilelic(
                    this.operador.documentacion.licencia,
                    respuesta.data
                  );
                }

                if (this.operador.documentacion.examen_medico != null) {
                  this.uploadFileExamen(
                    this.operador.documentacion.examen_medico,
                    respuesta.data
                  );
                }
              }
            })
            .catch(function () {});
        }
      } else {
        this.$swal("Debe ingresar nombre y apellido");
      }
    },nuevoOperador(){
       this.$router.push("/catalogos/operador/0");


    },
  
  

   
    editOperador(idoperador) {
             this.$router.push("/catalogos/operador/"+idoperador);

    },
    eliminarOperador(operador) {
      this.$swal({
        title: "Confirmación",
        text: "¿Desea eliminar al operador?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sí, eliminar",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post(url + "apiOperadores.php/delete_operador", operador)
            .then((respuesta) => {
              console.log(respuesta);

              if (respuesta.data.status === 200) {
                this.$swal("Operador Eliminado");
                this.veroperadores();
              }
            });
        }
      });
    },

    getidoperador(e) {
      if (e.target.options.selectedIndex > -1) {
        this.operador.idoperador = this.lista_operadores[
          e.target.options.selectedIndex
        ].idoperador;
      }
    },
    get_operadores() {
      this.axios
        .get(url + "apiOperadores.php/operadores")
        .then((respuesta) => {
          console.log(respuesta);
          this.lista_operadores = respuesta.data;

          console.log(this.lista_operadores);
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },
    ver_imagen(name) {
      this.axios
        .get(url + "apiOperadores.php/getImageDrive/" + name)
        .then((respuesta) => {
          console.log(respuesta);
          
          var image_data = respuesta.data;
          
          window.open(image_data.webViewLink);
        });
    },

   

    cancelarEdicion() {
      this.edit = false;
      this.limpiarcampos();
    },
  }
}
</script>
